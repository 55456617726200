<template>
  <div class="pay">
    <BaseHeaderBar
      title="Kode pembayaran"
      :showCustomerService="false"
      :showBackButton="true"
      color="#333"
      bgColor="#FFF"
    />
    <div class="pay-amount">
      <p class="label">Jumlah pembayaran</p>
      <p class="amount">Rp{{ payInfo.padryAmount }}</p>
    </div>
    <div class="pay-info-list">
      <div>
        <p class="label">Nama Bank</p>
        <p class="value">{{ payInfo.padryChannel }}</p>
      </div>
      <div>
        <p class="label">Metode pembayaran</p>
        <p class="value">{{ payInfo.padryWay }}</p>
      </div>
      <div>
        <p class="label">Masa berlaku</p>
        <p class="value">{{ payInfo.lodrseTime }}</p>
      </div>
      <div
        v-clipboard:copy="payInfo.padrymentCode"
        v-clipboard:success="onCopySuccess"
        v-clipboard:error="onCopyError"
      >
        <p class="label">
          Kode pembayaran
          <img class="copy-btn" src="@/assets/img/copy-btn.jpg" />
        </p>
        <p class="value">{{ payInfo.padrymentCode }}</p>
      </div>
    </div>
    <van-tabs
      class="extra-info"
      v-model="active"
      animated
      swipeable
      color="#1663FF"
      title-active-color="#1663FF"
      title-inactive-color="#333"
    >
      <van-tab title="Hal yang perlu diperhatikan">
        <div class="tab-content">
          <p v-for="(item, index) in payInfo.prdrecautions.step" :key="index">
            {{ item }}
          </p>
        </div>
      </van-tab>
      <van-tab title="Cara Pembayaran">
        <div class="tab-content">
          <p
            v-for="(item, index) in payInfo.redrpaymentProcess[0].step"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      </van-tab>
    </van-tabs>
    <button type="button" class="paid-btn" @click="onPaid">
      Saya sudah melakukan pembayaran
    </button>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
    >
      <p class="tip">Pembayaran berhasil！</p>
      <img
        class="repayment-success-icon"
        src="@/assets/img/repayment-success-icon.png"
      />
      <button
        v-if="payInfo.padryCodeid"
        type="button"
        class="ok-btn"
        @click="onConfirm"
      >
        ok
      </button>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Dialog, Toast } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import request from '@/utils/request'
import VueClipboard from 'vue-clipboard2'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(VueClipboard)

export default {
  name: 'Pay',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      payInfo: {
        prdrecautions: {},
        redrpaymentProcess: [{}]
      },
      active: 0
    }
  },
  created () {
    this.getPayCode()
  },
  methods: {
    getPayCode () {
      const { repayType, amount, orderId, channelId, payChannel, name, payWay, payWayName } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryAmount: amount,
        padryType: repayType,
        padrymentChannel: channelId,
        padryChannel: payChannel,
        padryChannelName: name,
        padryWay: payWay,
        padryWayName: payWayName
      }
      request.post('crdreatePaymentCode', postData)
        .then(res => {
          this.payInfo = res
        })
    },
    onCopySuccess () {
      Toast('Berhasil disalin')
    },
    onCopyError () {
      Toast('Penyalinan gagal')
    },
    onPaid () {
      const { orderId } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryCodeid: this.payInfo.padryCodeid
      }
      request.post('redrpaymentStatus', postData)
        .then(res => {
          if (res.padryStatus === 2) {
            this.visible = true
          } else {
            Toast(res.padryMsg)
          }
        })
    },
    onConfirm () {
      this.onCloseDialog()
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="less">
.pay-amount {
  margin-top: 85px;
  text-align: center;

  .label {
    font-size: 24px;
    color: #333;
  }

  .amount {
    font-size: 72px;
    font-weight: 600;
    color: #666;
  }
}

.pay-info-list {
  margin: 19px 48px 56px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    border-bottom: 1px solid #dcdedf;

    p {
      font-size: 26px;
    }

    .label {
      display: flex;
      align-items: center;
      color: #666;
    }

    .copy-btn {
      width: 30px;
      height: 30px;
      margin-left: 20px;
    }

    .value {
      font-weight: 600;
      color: #33404f;
    }
  }
}

.extra-info {
  border: 16px solid #1663ff;
  margin-left: 30px;
  margin-right: 30px;

  /deep/ .van-tab__text--ellipsis {
    overflow: visible;
    -webkit-line-clamp: none;
    white-space: nowrap;
    font-size: 26px;
  }
}

.tab-content {
  height: 415px;
  padding: 39px 42px 0;
  margin-bottom: 53px;
  overflow-y: scroll;

  p {
    line-height: 37px;
    font-size: 26px;
    color: #666;

    &:nth-child(n + 2) {
      margin-top: 40px;
    }
  }
}

.paid-btn {
  .confirm-btn;
  width: 689px;
  margin-top: 49px;
  margin-bottom: 40px;
}

.tip {
  line-height: 42px;
  margin-top: 83px;
  font-size: 30px;
  color: #333;
  text-align: center;
}

.repayment-success-icon {
  width: 286px;
  height: 306px;
  margin: 46px auto 0;
}

.confirm-btn {
  height: 88px;
  line-height: 88px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  color: #fff;
  text-align: center;
  background-color: #1663ff;
}

.ok-btn {
  .confirm-btn;
  width: 544px;
  margin-top: 53px;
  margin-bottom: 30px;
}
</style>
